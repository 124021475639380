import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material";
import { AddProgramBlocks, Bloque } from "src/app/classes/Programa";
import { ProgramService } from "src/app/services/program.service";
import { AddProgramBlocksComponent } from "../add-program-blocks/add-program-blocks.component";

@Component({
  selector: "app-select-program-blocks",
  templateUrl: "./select-program-blocks.component.html",
  styleUrls: ["./select-program-blocks.component.css"],
})
export class SelectProgramBlocksComponent implements OnInit {
  form: FormGroup;
  programs = ["Start", "Functional", "Fitness", "Advanced"];
  isLoading = false;

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public programaService: ProgramService
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      Date: [new Date()],
    });
  }

  openProgramBlocks(program: String) {
    this.isLoading = true;
    let date: Date = this.form.get("Date").value;
    date.setHours(12);
    this.programaService
      .GetDayProgram(
        this.programs.findIndex((x) => x === program) + 1,
        date.toJSON()
      )
      .subscribe((data) => {
        this.isLoading = false;
        const dialogRef = this.dialog.open(AddProgramBlocksComponent, {
          width: "500px",
          data: { program: data, date: date },
        });
        // this.isLoadingResults = false;
        dialogRef.afterClosed().subscribe((result) => {
          let addProgramBlocks = new AddProgramBlocks();
          addProgramBlocks.id =
            this.programs.findIndex((x) => x === program) + 1;
          addProgramBlocks.date = date;
          addProgramBlocks.BlocksDtos = [];
          if (result) {
            this.isLoading = true;
            result.forEach((element) => {
              let block = new Bloque();
              block.ejercicios = element;
              addProgramBlocks.BlocksDtos.push(block);
            });
            this.programaService
              .postProgramBlocks(addProgramBlocks)
              .subscribe((x) => (this.isLoading = false));
          }
        });
      });
  }
}
