import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { catchError, tap, map } from "rxjs/operators";
import { Persona } from "./classes/Persona";
import { PersonaReservaServicio } from "./classes/PersonaReservaServicio";
import { Reserva } from "./classes/Reserva";
import { Clase } from "./classes/Clase";
import { PersonaReserva } from "./classes/PersonaReserva";
import { ClaseDescript } from "./classes/ClaseDescript";
import { environment } from "src/environments/environment";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};
const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(private http: HttpClient) {}

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      //  console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(error as T);
    };
  }

  getDayHours(Day: string): Observable<ClaseDescript[]> {
    var tokenHeader = new HttpHeaders();
    return this.http
      .get<ClaseDescript[]>(apiUrl + "/Clases/Admin/" + Day, {
        headers: tokenHeader,
      })
      .pipe(tap());
  }

  getUsuariosClase(fecha: string): Observable<PersonaReservaServicio[]> {
    var tokenHeader = new HttpHeaders();
    return this.http
      .get<PersonaReservaServicio[]>(apiUrl + "/Clases/Usuarios/" + fecha, {
        headers: tokenHeader,
      })
      .pipe(tap(), catchError(this.handleError("getUsuariosClase", [])));
  }

  getDataClase(fecha: string): Observable<Clase> {
    var tokenHeader = new HttpHeaders();
    return this.http.get<Clase>(apiUrl + "/Clases/Existencia/" + fecha, {
      headers: tokenHeader,
    });
  }

  getUsuariosHabilitados(): Observable<PersonaReservaServicio[]> {
    var tokenHeader = new HttpHeaders();
    return this.http.get<PersonaReservaServicio[]>(
      apiUrl + "/Persona/UsuariosHabilitados",
      { headers: tokenHeader }
    );
  }

  getUsuariosConAviso(
    ClasFyH: string,
    ClasTipo: string
  ): Observable<PersonaReserva[]> {
    var tokenHeader = new HttpHeaders();
    return this.http.get<PersonaReserva[]>(
      apiUrl + "/Reserva/Avisos/" + ClasTipo + "/" + ClasFyH,
      { headers: tokenHeader }
    );
  }

  postAsistencias(
    ClasTipo: string,
    fecha: string,
    Usuarios: PersonaReservaServicio[]
  ): Observable<PersonaReservaServicio[]> {
    var tokenHeader = new HttpHeaders();
    return this.http
      .post<PersonaReservaServicio[]>(
        apiUrl + "/Reserva/Asistencias/" + ClasTipo + "/" + fecha,
        Usuarios,
        { headers: tokenHeader }
      )
      .pipe(tap(), catchError(this.handleError("postAsistencias", [])));
  }

  deleteReserva(
    ClasTipo: string,
    ClasFyH: string,
    cedula: number
  ): Observable<Reserva> {
    var tokenAndJsonHeaders = new HttpHeaders({
      /*'Authorization':'Bearer ' + localStorage.getItem('token'), */ "Content-Type":
        "application/json",
    });
    const url = `${apiUrl}/Reserva/Eliminar/Admin/${ClasTipo}/${ClasFyH}/${cedula}`;
    return this.http
      .delete<Reserva>(url, { headers: tokenAndJsonHeaders })
      .pipe(tap(), catchError(this.handleError<Reserva>("deleteReserva")));
  }

  EnviarNotificaciones(ClasTipo: string, ClasFyH: string): Observable<any> {
    var tokenAndJsonHeaders = new HttpHeaders({
      /*'Authorization':'Bearer ' + localStorage.getItem('token'), */ "Content-Type":
        "application/json",
    });
    const url = `${apiUrl}/Reserva/Avisos/Enviar/${ClasTipo}/${ClasFyH}`;
    return this.http.post<any>(url, { headers: tokenAndJsonHeaders }).pipe();
  }

  AsistenciaUsuario(
    Percedula: number,
    ClasTipo: string,
    ClasFyH: string
  ): Observable<any> {
    var tokenAndJsonHeaders = new HttpHeaders({
      /*'Authorization':'Bearer ' + localStorage.getItem('token'), */ "Content-Type":
        "application/json",
    });
    const url = `${apiUrl}/Reserva/Asistencias/Usuario/${Percedula}/${ClasTipo}/${ClasFyH}`;
    return this.http.post<any>(url, { headers: tokenAndJsonHeaders }).pipe();
  }
}
