import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Component, Inject, ViewChild} from '@angular/core';
import { PersonaReservaServicio } from 'src/app/classes/PersonaReservaServicio';
import { MatTableDataSource, MatSnackBar } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import {MatPaginator} from '@angular/material/paginator';
import { filter } from 'rxjs/operators';
import { ApiService } from 'src/app/api.service';


  export interface DialogData {
    usuariosConReserva: PersonaReservaServicio[];
    usuariosHabilitados: PersonaReservaServicio[];
  }

@Component({
    selector: 'app-asistencia-dialog',
    templateUrl: './asistencia-dialog.component.html',
  })



  export class AgregarUsuarioDialog {
    user= new PersonaReservaServicio();

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    constructor(
      public dialogRef: MatDialogRef<AgregarUsuarioDialog>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData) {}
  
    ngOnInit() {     
      this.dataSource.paginator=this.paginator;
    }

    confirmar(){
        this.selection.selected.forEach(element => {
          var per= this.data.usuariosConReserva.find(elementOD => elementOD.perCedula==element.perCedula);//me fijo que no este agregado
            if(per==null){
              this.data.usuariosConReserva.push(element);
            }
        })
        this.dialogRef.close();
    }

    onNoClick(): void {
      this.dialogRef.close();
    }

    displayedColumns: string[] = ['select', 'perCedula', 'perNombre', 'perApellido'];
    dataSource = new MatTableDataSource<PersonaReservaServicio>(this.data.usuariosHabilitados);
    selection = new SelectionModel<PersonaReservaServicio>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: PersonaReservaServicio): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.perCedula}`;
  }

  applyFilter(filterValue: string){
    this.dataSource.filter=filterValue.trim().toLowerCase();
  }
  
  }
