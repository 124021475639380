import { Injectable, OnDestroy } from "@angular/core";
import { MatSnackBar } from "@angular/material";
import { environment } from "src/environments/environment";
import {
  SnackBarComponent,
  MsgService,
} from "src/app/components/snack-bar/snack-bar.component";
import { Observable } from "rxjs/internal/Observable";
import { HttpClient } from "@angular/common/http";
import { AddProgramBlocks, Programa } from "../classes/Programa";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import {
  catchError,
  retry,
  share,
  switchMap,
  takeUntil,
  tap,
} from "rxjs/operators";
import { Subject } from "rxjs/internal/Subject";
import { timer } from "rxjs/internal/observable/timer";
import { of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ProgramService implements OnDestroy {
  apiUrl = environment.apiUrl;

  program$: Observable<Programa>[] = new Array();

  private stopPolling = new Subject();

  constructor(
    private _snackBar: MatSnackBar,
    private MsgService: MsgService,
    private http: HttpClient
  ) {}

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      //  console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(error as T);
    };
  }

  GetPrograma(id: number) {
    this.program$[id] = timer(1, 1000 * 60 * 5).pipe(
      switchMap(() => this.http.get<Programa>(this.apiUrl + "/Programa/" + id)),
      retry(),
      share(),
      takeUntil(this.stopPolling)
    );
  }

  GetDayProgramaPolling(id: number, date: string) {
    this.program$[id] = timer(1, 1000 * 60 * 5).pipe(
      switchMap(() =>
        this.http.get<Programa>(`${this.apiUrl}/Programa/${id}/${date}`)
      ),
      retry(),
      share(),
      takeUntil(this.stopPolling)
    );
  }

  GetDayProgram(id: number, date: string) {
    return this.http
      .get<Programa>(`${this.apiUrl}/Programa/${id}/${date}`)
      .pipe(tap(), catchError(this.handleError("getUsuariosClase", [])));
  }

  postProgramBlocks(content: AddProgramBlocks) {
    return this.http
      .put<Programa>(`${this.apiUrl}/Programa/${content.id}`, content)
      .pipe(tap(), catchError(this.handleError("postProgramBlocks", [])));
  }

  ngOnDestroy(): void {
    this.stopPolling.next();
  }
}
