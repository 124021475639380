import { Component, Inject, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Programa } from "src/app/classes/Programa";
import { AngularEditorConfig } from "@kolkov/angular-editor";

export interface data {
  program: Programa;
  date: Date;
}
@Component({
  selector: "app-add-program-blocks",
  templateUrl: "./add-program-blocks.component.html",
  styleUrls: ["./add-program-blocks.component.css"],
})
export class AddProgramBlocksComponent implements OnInit {
  form;
  bloques: FormArray;
  edition = [];
  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<Programa>,
    @Inject(MAT_DIALOG_DATA) public data: data
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({ bloques: this.formBuilder.array([]) });
    this.bloques = this.form.get("bloques") as FormArray;
    this.data.program.bloques.forEach((element) => {
      this.bloques.push(this.formBuilder.control(element.ejercicios));
      this.edition.push(false);
    });
  }

  addBloque() {
    this.bloques.push(this.formBuilder.control(""));
    this.edition.push(true);
  }

  removeBloque(index: number) {
    this.bloques.removeAt(index);
    this.edition.splice(index, 1);
  }

  editBloque(index: number) {
    this.edition[index] = !this.edition[index];
  }

  save() {
    this.dialogRef.close(this.bloques.value);
  }
  close() {
    this.dialogRef.close(false);
  }

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "auto",
    minHeight: "0",
    maxHeight: "auto",
    width: "auto",
    minWidth: "0",
    translate: "yes",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "Enter text here...",
    defaultParagraphSeparator: "",
    defaultFontName: "",
    defaultFontSize: "",
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: "top",
    toolbarHiddenButtons: [
      [
        "italic",
        "underline",
        "strikeThrough",
        "subscript",
        "superscript",
        "justifyLeft",
        "justifyCenter",
        "justifyRight",
        "justifyFull",
        "indent",
        "outdent",
        "insertUnorderedList",
        "insertOrderedList",
        "fontName",
      ],
      [
        "fontSize",
        "textColor",
        "backgroundColor",
        "customClasses",
        "link",
        "unlink",
        "insertImage",
        "insertVideo",
        "insertHorizontalRule",
        "removeFormat",
        "toggleEditorMode",
      ],
    ],
  };
}
