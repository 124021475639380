import { Component, OnInit, Injectable, Inject } from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material';

@Injectable()
export  class MsgService {
  Msg = null;
  Icon= null;

  SetIcon(Icon:string){
    this.Icon=Icon;
  }

  GetIcon(){
    return this.Icon
  }

  SetMsg(Msg:string){
    this.Msg=Msg;
  }

  GetMsg(){
    return this.Msg
  }
}

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.css']
})
export class SnackBarComponent implements OnInit {

  constructor(public MsgService: MsgService,
    
    public snackBarRef: MatSnackBarRef<SnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any) { }


  ngOnInit() {
  }

  
  
}
