export class Programa {
  id: number;
  nombre: string;
  bloques: Bloque[];
}

export class Bloque {
  id: number;
  bloque: number;
  fecha: Date;
  ejercicios: string;
  extra: string;
}

export class AddProgramBlocks {
  id: number;
  date: Date;
  BlocksDtos: Bloque[];
}
