import { Component } from "@angular/core";
import { Observable } from "rxjs";
import { Breakpoints, BreakpointObserver } from "@angular/cdk/layout";
import { shareReplay, map } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
//import { ApiService } from './api.service';
import { LoginService } from "./login/login.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "Sense Fitness Center";
}
