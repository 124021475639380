import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatDialogModule } from "@angular/material/dialog";
import {
  MatInputModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatSortModule,
  MatTableModule,
  MatIconModule,
  MatButtonModule,
  MatCardModule,
  MatFormFieldModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatStepperModule,
  MatSnackBarModule,
  MatTabsModule,
  MatSidenav,
} from "@angular/material";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LayoutModule } from "@angular/cdk/layout";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { MatGridListModule } from "@angular/material/grid-list";

import { LoginService } from "./login/login.service";
import { UserService } from "./login/users/user.service";
import { MatSelectModule } from "@angular/material/select";
import { AuthInterceptorService } from "./auth-service.service";
import { MsgService } from "./components/snack-bar/snack-bar.component";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login/login.component";
import { SnackBarComponent } from "./components/snack-bar/snack-bar.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  AsistenciaControlComponent,
  DialogDataDialog,
  DialogDataDialogNoti,
} from "./components/asistencia-control/asistencia-control.component";
import { AgregarUsuarioDialog } from "./components/asistencia-control/asistencia-dialog.component";
import { FilterPipe } from "./lists-filter.pipe";
import { usuariosConNotiDialog } from "./components/asistencia-control/asistenciaNoti-dialog.component";
import { InicioComponent } from "./components/inicio/inicio.component";
import { AsistenciaSociosControlComponent } from "./components/asistenciaSocios-control/asistenciaSocios-control.component";
import { WodComponent } from "./components/wod/wod.component";
import { NoLayoutComponent } from "./components/layouts/no-layout/no-layout.component";
import { BaseLayoutComponent } from "./components/layouts/base-layout/base-layout.component";
import { ProgramComponent } from "./components/program/program.component";
import { AddProgramBlocksComponent } from "./components/add-program-blocks/add-program-blocks.component";
import { SelectProgramBlocksComponent } from "./components/select-program-blocks/select-program-blocks.component";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { DatePipe } from "@angular/common";
import { ProgramsComponent } from './components/programs/programs.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SnackBarComponent,
    AsistenciaControlComponent,
    AsistenciaSociosControlComponent,
    AgregarUsuarioDialog,
    usuariosConNotiDialog,
    FilterPipe,
    DialogDataDialog,
    DialogDataDialogNoti,
    InicioComponent,
    WodComponent,
    NoLayoutComponent,
    BaseLayoutComponent,
    ProgramComponent,
    AddProgramBlocksComponent,
    SelectProgramBlocksComponent,
    ProgramsComponent,
  ],
  imports: [
    AngularEditorModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatStepperModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatSelectModule,
    MatGridListModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatListModule,
    MatTableModule,
    MatDialogModule,
    MatTabsModule,
  ],

  providers: [
    DatePipe,
    { provide: MAT_DATE_LOCALE, useValue: "es-GB" },
    LoginService,
    UserService,
    MsgService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
  ],

  entryComponents: [
    SnackBarComponent,
    AgregarUsuarioDialog,
    AddProgramBlocksComponent,
    DialogDataDialog,
    usuariosConNotiDialog,
    DialogDataDialogNoti,
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
