import { ProgramsComponent } from "./components/programs/programs.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./login/login/login.component";
import { AuthGuard } from "./login/auth.guard";
import { AsistenciaControlComponent } from "./components/asistencia-control/asistencia-control.component";
import { InicioComponent } from "./components/inicio/inicio.component";
import { AsistenciaSociosControlComponent } from "./components/asistenciaSocios-control/asistenciaSocios-control.component";
import { WodComponent } from "./components/wod/wod.component";
import { BaseLayoutComponent } from "./components/layouts/base-layout/base-layout.component";
import { NoLayoutComponent } from "./components/layouts/no-layout/no-layout.component";
import { ProgramComponent } from "./components/program/program.component";
import { AddProgramBlocksComponent } from "./components/add-program-blocks/add-program-blocks.component";
import { SelectProgramBlocksComponent } from "./components/select-program-blocks/select-program-blocks.component";
import { ProgramService } from "./services/program.service";

const routes: Routes = [
  {
    path: "",
    component: BaseLayoutComponent,
    children: [
      { path: "login", component: LoginComponent, pathMatch: "full" },

      {
        path: "Control",
        component: AsistenciaControlComponent,
        canActivate: [AuthGuard],
        pathMatch: "full",
      },

      {
        path: "Inicio",
        component: InicioComponent,
        canActivate: [AuthGuard],
        pathMatch: "full",
      },

      {
        path: "Wod",
        component: SelectProgramBlocksComponent,
        canActivate: [AuthGuard],
        pathMatch: "full",
      },

      {
        path: "Socios",
        component: AsistenciaSociosControlComponent,
        canActivate: [AuthGuard],
        pathMatch: "full",
      },

      {
        path: "",
        component: InicioComponent,
        canActivate: [AuthGuard],
        pathMatch: "full",
      },
    ],
  },

  {
    path: "Programa",
    component: NoLayoutComponent,
    children: [
      {
        path: "basics",
        component: ProgramsComponent,
        pathMatch: "full",
      },
      {
        path: ":program",
        component: ProgramComponent,
        pathMatch: "full",
      },
      {
        path: ":program/:date",
        canActivate: [AuthGuard],
        component: ProgramComponent,
        pathMatch: "full",
      },
    ],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
