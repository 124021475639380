import { LoginService } from "src/app/login/login.service";
import { Component, OnInit, ChangeDetectorRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import {
  MatSnackBar,
  MatDialog,
  MatTableDataSource,
  MatDialogRef,
  MatStepper,
} from "@angular/material";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Observable, BehaviorSubject } from "rxjs";
import { shareReplay, map } from "rxjs/operators";
import {
  MsgService,
  SnackBarComponent,
} from "src/app/components/snack-bar/snack-bar.component";
import { ApiService } from "src/app/api.service";
import { PersonaReservaServicio } from "src/app/classes/PersonaReservaServicio";
import { AgregarUsuarioDialog } from "./asistencia-dialog.component";
import { SelectionModel } from "@angular/cdk/collections";
import {
  STEPPER_GLOBAL_OPTIONS,
  CdkStepper,
  StepperSelectionEvent,
} from "@angular/cdk/stepper";
import { PersonaReserva } from "src/app/classes/PersonaReserva";
import { usuariosConNotiDialog } from "./asistenciaNoti-dialog.component";
import { ClaseDescript } from "src/app/classes/ClaseDescript";

@Component({
  selector: "app-asistencia-control",
  templateUrl: "./asistencia-control.component.html",
  styleUrls: ["./asistencia-control.component.css"],
})
export class AsistenciaControlComponent implements OnInit {
  @ViewChild("stepper", { static: false }) stepper: MatStepper;
  asistForm: FormGroup;
  DayHour: Date = new Date();
  Usuarios: any[];
  tipo: string = "";
  local: string;
  tipoDesc: string = "";
  isLoadingResults: boolean = true;
  usuario: PersonaReservaServicio;
  usuariosHabilitados: PersonaReservaServicio[];
  usuariosConNoti: PersonaReserva[];
  cupo: number = 0;
  Reserva: PersonaReservaServicio;
  displayedColumns: string[] = [
    "perCedula",
    "perNombre",
    "perApellido",
    "servNombre",
    "servValidez",
    "servRestantes",
    "resTemp",
    "resAsistio",
    "Delete",
  ];
  dataSource = new MatTableDataSource<PersonaReservaServicio>(this.Usuarios);
  selection = new SelectionModel<PersonaReservaServicio>(true, []);
  selectedReserva: PersonaReservaServicio;
  reservas;
  hours2: ClaseDescript[];
  hours1: ClaseDescript[];

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );
  FormGroup: FormGroup;

  constructor(
    private datePipe: DatePipe,
    private router: Router,
    private service: ApiService,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private breakpointObserver: BreakpointObserver,
    private MsgService: MsgService,
    private LoginService: LoginService,
    public dialog: MatDialog,
    private changeDetectorRefs: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.asistForm = this.formBuilder.group({
      Date: [this.DayHour],
    });
    this.isLoadingResults = false;
    this.local = this.LoginService.getCurrentSubsidariy();
  }

  getHours() {
    this.isLoadingResults = true;
    this.DayHour = this.asistForm.get("Date").value;
    this.DayHour.setHours(12);
    this.DayHour.setMinutes(0);
    this.DayHour.setSeconds(0);
    this.DayHour.setMilliseconds(0);
    this.service.getDayHours(this.DayHour.toUTCString()).subscribe(
      (res) => {
        this.hours1 = res.slice(0, res.length / 2);
        this.hours2 = res.slice(res.length / 2, res.length);
        this.isLoadingResults = false;
      },
      (err) => {
        console.log(err);
        this.isLoadingResults = false;
      }
    );
  }

  onClickHour(ClaseDescript: ClaseDescript) {
    this.isLoadingResults = true;
    let t = ClaseDescript.time; // hh:mm
    this.DayHour.setHours(Number(t.split(":")[0]));
    this.DayHour.setMinutes(Number(t.split(":")[1]));

    this.asistForm.get("Date").setValue(this.DayHour);

    this.service
      .getDataClase(
        this.datePipe.transform(this.DayHour.toUTCString(), "yyyy-MM-dd HH:mm")
      )
      .subscribe(
        (res) => {
          this.cupo = res.clasCupo;
          this.tipo = res.clasTipo;
          if (res.clasTipo == "C") {
            this.tipoDesc = "Fitness";
          } else if (res.clasTipo == "O") {
            this.tipoDesc = "Open Sense";
          } else this.tipoDesc = "TALLER";
          this.service
            .getUsuariosClase(
              this.datePipe.transform(
                this.DayHour.toUTCString(),
                "yyyy-MM-dd HH:mm"
              )
            )
            .subscribe(
              (data) => {
                this.Usuarios = data;
                this.dataSource =
                  new MatTableDataSource<PersonaReservaServicio>(this.Usuarios);
                this.isLoadingResults = false;
              },
              (err) => {
                this.isLoadingResults = false;
              }
            );
        },
        (err) => {
          this.MsgService.SetMsg(err.error.message);
          this.MsgService.SetIcon("warning");
          this._snackBar.openFromComponent(SnackBarComponent, {
            duration: 5000,
            verticalPosition: "top",
            panelClass: ["warn-snackbar"],
          });
          this.stepper.reset();
          this.router.navigate([""]);
          this.isLoadingResults = false;
        }
      );
  }

  agregarUsuario(): void {
    this.isLoadingResults = true;
    this.service.getUsuariosHabilitados().subscribe(
      (data) => {
        this.usuariosHabilitados = data;
        const dialogRef = this.dialog.open(AgregarUsuarioDialog, {
          width: "500px",
          data: {
            usuariosConReserva: this.Usuarios,
            usuariosHabilitados: this.usuariosHabilitados,
          },
        });
        this.isLoadingResults = false;
        dialogRef.afterClosed().subscribe((result) => {
          this.dataSource = new MatTableDataSource<PersonaReservaServicio>(
            this.Usuarios
          );
        });
      },
      (error) => {
        this.MsgService.SetMsg("Error");
        this.MsgService.SetIcon("warning");
        this._snackBar.openFromComponent(SnackBarComponent, {
          duration: 5000,
          verticalPosition: "top",
          panelClass: ["warn-snackbar"],
        });
        this.isLoadingResults = false;
      }
    );
  }

  notificaciones() {
    this.isLoadingResults = true;
    this.service
      .getUsuariosConAviso(
        this.datePipe.transform(this.DayHour.toUTCString(), "yyyy-MM-dd HH:mm"),
        "C"
      )
      .subscribe(
        (data) => {
          this.usuariosConNoti = data;
          const dialogRef = this.dialog.open(usuariosConNotiDialog, {
            width: "500px",
            data: this.usuariosConNoti,
          });
          this.isLoadingResults = false;
          dialogRef.afterClosed().subscribe((result) => {});
        },
        (error) => {
          this.MsgService.SetMsg("Error");
          this.MsgService.SetIcon("warning");
          this._snackBar.openFromComponent(SnackBarComponent, {
            duration: 5000,
            verticalPosition: "top",
            panelClass: ["warn-snackbar"],
          });
          this.isLoadingResults = false;
        }
      );
  }

  cambiarAsistencia(usuario: PersonaReservaServicio) {
    usuario.resAsistio = !usuario.resAsistio;
  }

  changeTemperatura(value: string, usu: PersonaReservaServicio) {
    usu.resTemp = Number(value);
  }

  enviarAsistencias() {
    this.isLoadingResults = true;
    this.Usuarios = this.dataSource.data;
    this.service
      .postAsistencias(
        this.tipo,
        this.datePipe.transform(this.DayHour.toUTCString(), "yyyy-MM-dd HH:mm"),
        this.Usuarios
      )
      .subscribe(
        (data) => {
          this.MsgService.SetMsg("Asistencias confirmadas");
          this.MsgService.SetIcon("done");
          this._snackBar.openFromComponent(SnackBarComponent, {
            duration: 5000,
            verticalPosition: "top",
            panelClass: ["success-snackbar"],
          });
          this.router.navigate([""]);
          this.isLoadingResults = false;
        },

        (error) => {
          this.MsgService.SetMsg("Error");
          this.MsgService.SetIcon("warning");
          this._snackBar.openFromComponent(SnackBarComponent, {
            duration: 5000,
            verticalPosition: "top",
            panelClass: ["warn-snackbar"],
          });
          this.isLoadingResults = false;
        }
      );
  }

  onDelete(ClasFyH: string, persona: PersonaReservaServicio) {
    this.isLoadingResults = true;
    this.service.deleteReserva(this.tipo, ClasFyH, persona.perCedula).subscribe(
      (res) => {
        const index = this.dataSource.data.indexOf(persona);
        this.dataSource.data.splice(index, 1);
        this.dataSource.data = this.dataSource.data;
        this.isLoadingResults = false;
      },
      (err) => {
        console.log(err);
        const index = this.dataSource.data.indexOf(persona);
        this.dataSource.data.splice(index, 1);

        this.isLoadingResults = false;
      }
    );
  }

  EnviarNotificaciones() {
    const dialogRef = this.dialog.open(DialogDataDialogNoti);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.isLoadingResults = true;
        this.service
          .EnviarNotificaciones(
            this.tipo,
            this.datePipe.transform(
              this.DayHour.toUTCString(),
              "yyyy-MM-dd HH:mm"
            )
          )
          .subscribe(
            (res) => {
              this.MsgService.SetMsg("Notificaciones Enviadas");
              this.MsgService.SetIcon("done");
              this._snackBar.openFromComponent(SnackBarComponent, {
                duration: 5000,
                verticalPosition: "top",
                panelClass: ["success-snackbar"],
              });
              this.isLoadingResults = false;
            },
            (error) => {
              this.MsgService.SetMsg("Error");
              this.MsgService.SetIcon("warning");
              this._snackBar.openFromComponent(SnackBarComponent, {
                duration: 5000,
                verticalPosition: "top",
                panelClass: ["warn-snackbar"],
              });
              this.isLoadingResults = false;
            }
          );
      }
    });
  }

  openDialog(persona: PersonaReservaServicio): void {
    const dialogRef = this.dialog.open(DialogDataDialog);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log("The dialog was closed");
        this.onDelete(
          this.datePipe.transform(
            this.DayHour.toUTCString(),
            "yyyy-MM-dd HH:mm"
          ),
          persona
        );
      }
    });
  }
}

@Component({
  selector: "dialog-overview-dialog",
  templateUrl: "./dialogo.component.html",
})
export class DialogDataDialog {
  constructor(public dialogRef: MatDialogRef<DialogDataDialog>) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: "dialog-overview-dialogNoti",
  templateUrl: "./dialogoNoti.component.html",
})
export class DialogDataDialogNoti {
  constructor(public dialogRef: MatDialogRef<DialogDataDialogNoti>) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
