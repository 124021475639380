export class PersonaReservaServicio
{
     perCedula : number;
     perNombre: String;
     perApellido: string;
     resAsistio:boolean;
     resTemp:number;
     servNombre: string;
     servValidez:Date;
     servRestantes:number;
}