import { Component, OnInit } from "@angular/core";
import { LoginService } from "../login.service";
import { Router } from "@angular/router";
import { User } from "../users/user.model";
import { MatSnackBar } from "@angular/material/snack-bar";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {
  SnackBarComponent,
  MsgService,
} from "src/app/components/snack-bar/snack-bar.component";
import { BoundElementPropertyAst } from "@angular/compiler";
import { Empresa } from "src/app/classes/Empresa";
import { NgPluralCase } from "@angular/common";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup = null;
  isLoadingResults = false;
  local: Empresa = new Empresa();
  locales: Empresa[];

  constructor(
    private router: Router,
    private loginService: LoginService,
    private _snackBar: MatSnackBar,
    private MsgService: MsgService
  ) {}
  error: null;

  user: User = {
    email: "",
    password: "",
    empCod: 0,
  };

  ngOnInit() {
    if (localStorage.getItem("token") != null) {
      this.router.navigate([""]);
    }

    this.loginService.getlocales().subscribe((res) => {
      this.locales = res;
      this.local = res[0];
    });
  }

  onLogin() {
    this.isLoadingResults = true;
    this.user.empCod = this.local.empCod;
    console.log(this.local)
    localStorage.setItem("empresa", this.local.empNom);
    return this.loginService
      .login(this.user.empCod, this.user.email, this.user.password)
      .subscribe(
        (data) => {
          localStorage.setItem("token", data.token);
          localStorage.setItem("usuario", data.name.trim());
          this.router.navigate(["/Inicio"]);
          this.isLoadingResults = false;
        },
        (error) => {
          this.user.password = null;
          this.MsgService.SetMsg(error.error.message);
          this.MsgService.SetIcon("warning");
          this._snackBar.openFromComponent(SnackBarComponent, {
            duration: 5000,
            verticalPosition: "top",
            panelClass: ["warn-snackbar"],
          });
          this.isLoadingResults = false;
          this.error = error.error.message;
        }
      );
  }

  navigate() {
    this.router.navigateByUrl("");
  }
}
