import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Component, Inject, ViewChild} from '@angular/core';
import { PersonaReservaServicio } from 'src/app/classes/PersonaReservaServicio';
import { MatTableDataSource } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import {MatPaginator} from '@angular/material/paginator';
import { filter } from 'rxjs/operators';
import { PersonaReserva } from 'src/app/classes/PersonaReserva';


@Component({
    selector: 'app-asistenciaNoti-dialog',
    templateUrl: './asistenciaNoti-dialog.component.html',
  })



  export class usuariosConNotiDialog {

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    constructor(
      public dialogRef: MatDialogRef<usuariosConNotiDialog>,
      @Inject(MAT_DIALOG_DATA) public data: PersonaReserva[]) {}
  
    ngOnInit() {     
      this.dataSource.paginator=this.paginator;
    }

    confirmar(){
        this.dialogRef.close();
    }

    onNoClick(): void {
      this.dialogRef.close();
    }

    displayedColumns: string[] = ['perCedula', 'perNombre', 'perApellido','clasFyH'];
    dataSource = new MatTableDataSource<PersonaReserva>(this.data);

  applyFilter(filterValue: string){
    this.dataSource.filter=filterValue.trim().toLowerCase();
  }
  
  }