import { Breakpoints, BreakpointObserver } from "@angular/cdk/layout";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { LoginService } from "src/app/login/login.service";

@Component({
  selector: "app-base-layout",
  templateUrl: "./base-layout.component.html",
  styleUrls: ["./base-layout.component.css"],
})
export class BaseLayoutComponent {
  logged = false;
  user = "";

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  constructor(
    private route: ActivatedRoute,
    /*private api: ApiService,*/ private loginService: LoginService,
    private router: Router,
    private breakpointObserver: BreakpointObserver
  ) {}

  public open(ruta: string) {
    this.router.navigate([ruta]);
  }

  public readToken(): any {
    if (localStorage.getItem("token") != null) return true;
    else return false;
  }
  public readUser(): any {
    if (localStorage.getItem("usuario") != null) {
      this.user = localStorage.getItem("usuario");
      return true;
    } else return false;
  }
  onLogout(): void {
    this.loginService.logout();
  }
}
