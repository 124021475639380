import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { User } from "./users/user.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { isNullOrUndefined } from "util";
import { Empresa } from "../classes/Empresa";
import { environment } from "src/environments/environment";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};
const apiUrl = environment.apiUrl;
@Injectable()
export class LoginService {
  constructor(private http: HttpClient) {}

  login(
    empCod: number,
    perEmail: string,
    perPassword: string
  ): Observable<any> {
    const url = `${apiUrl}/login/Asistencias`;
    return this.http
      .post<User>(url, { empCod, perEmail, perPassword }, httpOptions)
      .pipe(map((data) => data));
  }

  getToken() {
    return localStorage.getItem("token");
  }

  getCurrentUser(): User {
    let user_string = localStorage.getItem("user");
    if (!isNullOrUndefined(user_string)) {
      let user: User = JSON.parse(user_string);
      return user;
    } else {
      return null;
    }
  }

  getCurrentSubsidariy(): string {
    return localStorage.getItem("empresa");
  }

  logout() {
    let Token = localStorage.getItem("token");
    const url = `${apiUrl}/login/${Token}`;
    localStorage.removeItem("token");
    localStorage.removeItem("usuario");
    return this.http.post<User>(url, httpOptions);
  }

  getlocales(): Observable<Empresa[]> {
    const url = `${apiUrl}/Persona/locales`;
    return this.http.get<Empresa[]>(url, httpOptions).pipe(map((data) => data));
  }
}
