import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar, MatDialog, MatTableDataSource, MatDialogRef, MatStepper } from '@angular/material';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, BehaviorSubject } from 'rxjs';
import { shareReplay, map } from 'rxjs/operators';
import { MsgService, SnackBarComponent } from 'src/app/components/snack-bar/snack-bar.component';
import { ApiService } from 'src/app/api.service';
import { PersonaReservaServicio } from 'src/app/classes/PersonaReservaServicio';
import { SelectionModel } from '@angular/cdk/collections';
import { STEPPER_GLOBAL_OPTIONS, CdkStepper, StepperSelectionEvent } from '@angular/cdk/stepper';
import { PersonaReserva } from 'src/app/classes/PersonaReserva';
import { ClaseDescript } from 'src/app/classes/ClaseDescript';


@Component({
  selector: 'app-asistenciaSocios-control',
  templateUrl: './asistenciaSocios-control.component.html',
  styleUrls: ['./asistenciaSocios-control.component.css']
})
export class AsistenciaSociosControlComponent implements OnInit {
  @ViewChild('stepper' ,{ static: false }) stepper: MatStepper;
  asistForm: FormGroup;
  DayHour: Date = new Date();
  Usuarios: any[];
  tipo:string ="";
  local:string;
  tipoDesc:string ="";
  isLoadingResults:boolean=true;
  hours2:ClaseDescript[]
  hours1:ClaseDescript[]
  


  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );
  FormGroup: FormGroup;

  constructor(private router: Router, private service: ApiService, private formBuilder: FormBuilder, 
    private breakpointObserver: BreakpointObserver,private _snackBar: MatSnackBar,
    private MsgService: MsgService, public dialog: MatDialog, private changeDetectorRefs: ChangeDetectorRef) { }

  ngOnInit() {
    this.asistForm = this.formBuilder.group({   
        Date: [this.DayHour]  
      
    });
    this.isLoadingResults=false;
  }

  getHours(){
    this.isLoadingResults=true;
    this.DayHour=this.asistForm.get('Date').value;
    this.service.getDayHours(this.DayHour.toUTCString()).subscribe(
      res =>{
         this.hours1=res.slice(0,res.length/2);
         this.hours2=res.slice(res.length/2,res.length);
         this.isLoadingResults=false;
      },
      err => {
       console.log(err);
       this.isLoadingResults=false;
     })
  }

  onClickHour(hour: number){
    this.isLoadingResults=true;
    this.DayHour=this.asistForm.get('Date').value;
    this.DayHour.setHours(hour);
    this.DayHour.setMinutes(0);
    this.DayHour.setSeconds(0);
    this.DayHour.setMilliseconds(0)
    this.asistForm.get('Date').setValue(this.DayHour);

    this.service.getDataClase(this.DayHour.toJSON()).subscribe(
      res=> {
            this.tipo=res.clasTipo;
            if(res.empCod==1){
              this.local ="Sense Pocitos"
            }
            else{
              this.local ="Sense La Costa"
            }
            if(res.clasTipo=="C"){
              this.tipoDesc="Fitness";
            }else if(res.clasTipo=="O"){
                this.tipoDesc="Open Sense";
                }
              else
              this.tipoDesc="TALLER";
            this.service.getUsuariosClase(this.DayHour.toJSON()).subscribe(
            data => {        
                    this.Usuarios=data;
                    this.Usuarios.forEach(element => {
                      if(element.resAsistio){
                        this.Usuarios=this.Usuarios.filter(x=>x.perCedula!=element.perCedula)
                      }
                    });
                    console.log(this.Usuarios);
                    this.isLoadingResults=false; 
                  }, err => {
                    console.log(err);
                    this.isLoadingResults = false;
                  }
          ); 

      }
      , err => {
        this.MsgService.SetMsg(err.error.message);
      this.MsgService.SetIcon("warning");
      this._snackBar.openFromComponent(SnackBarComponent, {
        duration: 5000,
        verticalPosition: 'top',
        panelClass: ['warn-snackbar'],
        });
        this.stepper.reset();
        this.router.navigate(['']);
        this.isLoadingResults=false;
        }
    )
      
    }
    
    usuarioAsistio(usuario: PersonaReservaServicio ){
      this.isLoadingResults=true;
      this.service.AsistenciaUsuario(usuario.perCedula,this.tipo,this.DayHour.toJSON()).subscribe(
        res =>{
          this.MsgService.SetMsg("Asistencia confirmada!");
          this.MsgService.SetIcon("done");
          this._snackBar.openFromComponent(SnackBarComponent, {
            duration: 1000,
            verticalPosition: 'top',
            panelClass: ['success-snackbar'],
            });
            this.isLoadingResults=false;
            this.Usuarios=this.Usuarios.filter(x=>x.perCedula!=usuario.perCedula)
        },err => {
          this.MsgService.SetMsg(err.error.message);
          this.MsgService.SetIcon("warning");
          this._snackBar.openFromComponent(SnackBarComponent, {
            duration: 5000,
            verticalPosition: 'top',
            panelClass: ['warn-snackbar'],
            });
            this.isLoadingResults=false;
        }
      )
    }

}


